import api from '@/services/apiService'

export default {
    state: {
      legalEntities: null
    },
    getters: {
        legalEntities: (state) => {
          return state.legalEntities
      }
    },
    mutations: {
      setLegalEntities: (state, payload) => {
        state.legalEntities = payload
      }
    },
    actions: {
        loadLegalEntities: ({ commit }) => {
            api.getLegalEntities().then((LEs) => {
                commit('setLegalEntities', LEs)
            })
        }
    }
}
  