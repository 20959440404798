// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store/store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import httpService from '@/services/http'
import apiService from '@/services/apiService'
import moment from 'moment'
import 'moment/locale/nl-be';
import modalservice from './services/modalservice'
import * as environment from './environment.json'
import Question from './components/Question'
moment.locale('nl-be');

window.setLocale = (lang) => { i18n.locale = lang }
window.getLocale = () => i18n.locale

Vue.directive('cdn-src', {
  bind: function (el, binding, vnode) {
    el.setAttribute("src", environment.VUE_APP_CDN_BASE_URL + binding.value);
  }
})

Vue.prototype.$api = apiService
Vue.prototype.$http = httpService
Vue.prototype.$env = environment

Vue.prototype.$openModal = modalservice.open

Vue.prototype.$askConfirmDelete = (parent) => {
  return modalservice.open(Question, { isDeleteQuestion: true }, parent)
}

Vue.prototype.$showSuccess = (message) => {
  if (!message)
    message = "Succes!" // TODO translated
  modalservice.showSuccess(message)
}

Vue.prototype.$showError = (message) => {
  if (!message)
    message = "Oeps.. er ging iets mis!" // TODO translated
  modalservice.showError(message)
}

Vue.prototype.$formatDate = function (date, hideTime) {
  if (!date) return ""
  if (hideTime) return moment(date).format('ll')
  return moment(date).format('llll')
}

Vue.prototype.$getTranslation = function (obj) {
  const lang = window.getLocale()
  if (!obj || !lang) return ""
  const langfallbacks = [lang, 'nl', 'en', 'fr']
  for (let trylang of langfallbacks)
    if (obj[trylang]) return obj[trylang]
  return ""
}

Vue.prototype.$formatTime = function (datetime) {
  if (!datetime) return ""
  return moment(datetime).format('LTS')
}

Vue.prototype.$forEachPromise = (items, fn) => {
  return items.reduce((promise, item) => {
    return promise.then(() => fn(item));
  }, Promise.resolve());
}

Vue.prototype.$asCurrency = function (amount) {
  if (typeof amount !== "undefined" && amount != null)
    return amount.toLocaleString('nl-be', { style: 'currency', currency: 'EUR' });
  return null
}

Vue.config.productionTip = false

import loyaltyGetStarted from './workspace/loyalty/loyaltyGetStarted';
Vue.component('loyalty-get-started', loyaltyGetStarted)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
