<template>

<v-snackbar
      v-model="snackbar"
      :multi-line="true"
      right
      top
      :timeout="3500"
      :color="color"
    > 
     {{message}}
    </v-snackbar>


</template>

<script>
export default {
    props: {
        message: String,
        color: String
    },
    data () {
        return {
            snackbar: true
        }
    }
}
</script>