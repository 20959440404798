import Vue from "vue";
import Router from "vue-router";
import * as environment from "./environment.json";

Vue.use(Router);

export default new Router({
  mode: "hash",
  //base: environment.BASE_URL,
  routes: [
    {
      path: "/login",
      component: () => import("@/landing/landing"),
      children: [
        {
          name: "login",
          path: "",
          component: () => import("@/landing/login"),
          props: true
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/dashboard/Index"),
      children: [
        // Pages
        {
          name: "Notifications",
          path: "components/notifications",
          component: () => import("@/views/dashboard/component/Notifications")
        },
        {
          name: "Icons",
          path: "components/icons",
          component: () => import("@/views/dashboard/component/Icons")
        },
        {
          name: "Typography",
          path: "components/typography",
          component: () => import("@/views/dashboard/component/Typography")
        },
        // Tables
        {
          name: "Regular Tables",
          path: "tables/regular-tables",
          component: () => import("@/views/dashboard/tables/RegularTables")
        },
        // Maps
        {
          name: "Google Maps",
          path: "maps/google-maps",
          component: () => import("@/views/dashboard/maps/GoogleMaps")
        },
        // Upgrade
        {
          name: "Upgrade",
          path: "upgrade",
          component: () => import("@/views/dashboard/Upgrade")
        },
        // bonsai
        {
          name: "home",
          path: "",
          component: () => import("@/workspace/business/business"),
          meta: {
            leRequired: true
          }
        },
        {
          name: "userProfile",
          path: "/userProfile",
          component: () => import("@/workspace/UserProfile")
        },
        {
          name: "support",
          path: "support",
          component: () => import("@/workspace/Support")
        },
        {
          name: "business",
          path: "business",
          component: () => import("@/workspace/business/business"),
          meta: {
            leRequired: true
          }
        },
        {
          name: "loyalty",
          path: "loyalty",
          component: () => import("@/workspace/loyalty/loyalty"),
          meta: {
            leRequired: true,
            infoComponent: "loyalty-get-started"
          }
        },
        {
          name: "transactions",
          path: "transactions",
          component: () => import("@/workspace/transactions/transactions"),
          meta: {
            leRequired: true
          }
        },
        {
          name: "cashiers",
          path: "cashiers",
          component: () => import("@/workspace/cashiers/cashiers"),
          meta: {
            leRequired: true
          }
        },
        {
          name: "invoices",
          path: "invoices",
          component: () => import("@/workspace/invoices/invoices"),
          meta: {
            leRequired: true
          }
        },
        {
          name: "integrations",
          path: "integrations",
          component: () => import("@/workspace/integrations/integrations")
        },
        {
          name: "accept",
          path: "accept",
          component: () => import("@/workspace/accept/accept")
        },
        {
          name: "lightspeedcallback",
          path: "lightspeed-callback",
          component: () =>
            import("@/workspace/integrations/lightspeed-callback")
        }
      ]
    }
  ]
});
