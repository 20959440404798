<template>
<v-app>
    <router-view />

  </v-app>
  
</template>

<script>
const uuid = require('uuid')
  export default {
    name: 'App',
    mounted() {
       const token = window.localStorage.getItem('deviceToken')
       if (!token) window.localStorage.setItem('deviceToken', uuid.v4())
    }
  }
</script>
