<template>
    <div>
        <div class="mb-3 text-h3">{{cTitle}}</div>
        <p>{{cQuestion}}</p>

        <v-btn @click="yes" color="primary">{{$t('labels.yes')}}</v-btn>
        <v-btn @click="no">{{$t('labels.no')}}</v-btn>
    </div>

</template>

<script>
export default {
    props: {
        isDeleteQuestion: Boolean,
        title: String,
        question: String
    },
    computed: {
        cTitle() { return this.isDeleteQuestion ? this.$t('labels.deleteQuestionTitle') : this.title },
        cQuestion() { return this.isDeleteQuestion ? this.$t('labels.deleteQuestionMessage') : this.title },
    },
    methods: {
        yes () {
            this.$emit('ok')
        },
        no () {
            this.$emit('close')
        }
    }
}
</script>
