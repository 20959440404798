import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'
import TreeIcon from '@/components/treeIcon.vue'

Vue.use(Vuetify)

import colors from '@/sass/colors.scss'
import '@/sass/modal.scss'

const theme = {
  primary: colors.bonsaiBlue,
  secondary: '#9C27b0',
  accent: colors.bonsaiGreen,
  info: colors.bonsaiOrange,
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
  icons: {
    values: {
      'bonsai-tree': {
        component: TreeIcon
      },
    },
  },
})