<template>
    
    <div>

        <p class="display-3 text--disabled">{{$t('actions.startWithLoyalty.title')}}</p>

        <v-layout class="ma-0">

            <v-flex>

                <div v-html="$t('actions.startWithLoyalty.text')"></div>

                <v-btn class="mt-3" color="info" :disabled="!hasBrands" @click="start">{{$t('actions.startWithLoyalty.getStartedBtn')}}</v-btn>

            </v-flex>

            <div class="d-none d-md-block" style="width: 30%">
                <img style="width:100%" src="@/assets/loyalty_phone.png" />
            </div>

        </v-layout>

      
    </div>

</template>

<script>
export default {
    methods: {
        start() {
            this.$emit("confirm")
        }
    },
    
    computed: {
        hasBrands() {
            return this.$store.getters.brands && this.$store.getters.brands.length
        }
    }
}
</script>