import api from '@/services/apiService'

export default {
  state: {
    userProfile: null
  },
  getters: {
    userProfile: (state) => {
      return state.userProfile
    },
    user: (state) => {
      if (state.userProfile) return state.userProfile.user
      return {}
    }
  },
  mutations: {
    setUserProfile: (state, payload) => {
      window.setLocale(payload.user.langCode)
      state.userProfile = payload
    }
  },
  actions: {
    loadUserProfile: ({ commit }) => {
      api.getUserProfile().then((profile) => {
        commit('setUserProfile', profile)
      })
    }
  }
}
