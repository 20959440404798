import Modal from './Modal'
import Snackbar from './Snackbar.vue'
import Vuetify from 'vuetify/lib';
import Vue from 'vue'

// Parent object is needed to let vuex work

const close = function (instance) {
  instance.$destroy()
  instance.$el.remove() // remove from DOM
  instance = undefined
}

const generateContainer = function (category) {
  const id = category + 'Container'
  let CategoryContainerElement = document.getElementById(id)
  if (!CategoryContainerElement) {
    CategoryContainerElement = document.createElement('div')
    CategoryContainerElement.setAttribute('id', id)
    CategoryContainerElement.setAttribute('class', 'application theme--light')
    document.querySelector('[data-app]').appendChild(CategoryContainerElement)
  }

  const random = Math.floor(Math.random() * 100)
  var instanceContainer = document.createElement('div')
  instanceContainer.setAttribute('id', 'container' + random)
  CategoryContainerElement.appendChild(instanceContainer)
  return 'container' + random
}

export default {
  open: function (innerComponent, componentProps, parentObject, modalProps) {
    if (!componentProps) componentProps = {}
    if (!modalProps) modalProps = {}
    const containerId = generateContainer('modal')

    // Create the modal
    const ModalCtor = Vue.extend(Modal)
    modalProps = Object.assign(modalProps, { container: containerId })
    const modalInstance = new ModalCtor({propsData: modalProps, parent: parentObject})
    modalInstance.$vuetify = new Vuetify().framework;

    // Mount in container & attach listeners
    modalInstance.$mount('#' + containerId)

    // create innerComponent vue instance
    const InnerComponentCtor = Vue.extend(innerComponent)
    const innerComponentInst = new InnerComponentCtor({propsData: componentProps, parent: parentObject})

    // Mount innerComponent in modal
    innerComponentInst.$mount('#' + containerId + '_content')
    return new Promise((resolve, reject) => {
      innerComponentInst.$on('ok', (param) => {
        close(modalInstance)
        resolve(param)
      })
      innerComponentInst.$on('close', (param) => {
        close(modalInstance)
        reject()
      })
      modalInstance.$on('close', () => {
        close(modalInstance)
        // eslint-disable-next-line
        reject()
      })
    })
  },
  showSuccess: function (message) {
    const containerId = generateContainer('snackbar')

    // Create the modal
    const ModalCtor = Vue.extend(Snackbar)
    
    const modalProps = { message, color : "success", container: containerId }
    const modalInstance = new ModalCtor({propsData: modalProps})
    modalInstance.$vuetify = new Vuetify().framework;

    // Mount in container & attach listeners
    modalInstance.$mount('#' + containerId)
  },
  showError: function (message) {

    if (!message)
      message = "General error"

    const containerId = generateContainer('snackbar')

    // Create the modal
    const ModalCtor = Vue.extend(Snackbar)
    const modalProps = { message, color : "error", container: containerId }
    const modalInstance = new ModalCtor({propsData: modalProps})
    modalInstance.$vuetify = new Vuetify().framework;

    // Mount in container & attach listeners
    modalInstance.$mount('#' + containerId)
  }
}
