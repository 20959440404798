var FileSaver = require('file-saver');
import http from './http'

async function getUserProfile() {
    const response = await http.get('users/profile?v=2')
    return response.data
}

async function getUserLocation() {
    const response = await http.get('payers/address')
    return response.data
}

async function getLegalEntities() {
    const response = await http.get('acceptors/legalEntity')
    return response.data.companies
}

async function getBrands() {
    const response = await http.get(`acceptors/brands`)
    return response.data.brands
}

async function getAccounts() { // all receiving accounts. Also if you're an cashier for someone else ^^
    const response = await http.get(`acceptors/accounts`)
    return response.data.accounts
}

async function getCashiers() {
    const response = await http.get('acceptors/cashiers')
    return response.data.Cashiers
}

function deleteCashier(id) {
    return http.delete(`acceptors/cashiers/${id}`)
}

function addCashier(leId, brandId, email) {
    return http.post(`acceptors/${leId}/${brandId}/cashier`, { email })
}

async function getInvoices() {
    const response = await http.get(`acceptors/invoices`)
    return response.data.invoices
}

async function downloadCsv(filters, dateFrom, dateTo, cutoffHour) {
    const response = await http.post(`transactions/csv`, {
        filters,
        dateFrom,
        dateTo,
        cutoffHour,
        wrapped: true
    })
    var blob = new Blob([response.data.csv], { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(blob, "transactions.csv");
}

/**
 * Get transactions. All parameters are optional
 */
async function getTransactions(page, filters, dateFrom, dateTo, cutoffHour, granularity) {
    if (!page) page = 0
    const response = await http.post(`transactions/list`, {
        page,
        asBusiness: true,
        filters: filters || [],
        dateFrom,
        dateTo,
        cutoffHour,
        granularity
    })
    return {
        nextPage: response.data.nextPage,
        transactions: response.data.transactions
    }
}

/**
 * Change the personal address. Not brand or LE
 */
function changeAddress({ street, number, postalCode, city }) {
    const countryCode = 'BE'
    return http.post('payers/address', { street, number, postalCode, city, countryCode })
}

function changeEmail(email) {
    return http.post('users/changeEmailAddress', { email })
}

async function generateStaticQR(brandId) {
    const response = await http.post(`acceptors/brands/qr?brandId=${brandId}`)
    return response.data
}

async function createTrx(accountId, locationId, reference, amount) {
    const response = await http.post('transactions', {
        amount,
        receiverAccountID: accountId,
        receiverLocationID: locationId,
        reference,
        overriddenTimeoutDays: 30
    })
    return response.data
}

function sendSupportquestion(message) {
    return http.post('supportquestion', { message, subject: 'Merchant dashboard question' })
}

async function loadTrxFilter() {
    const response = await http.get('transactions/list/filters?asBusiness=true')
    return response.data.filters
}

async function register(data) {
    data.source = "webportal"
    const response = await http.post('users/register?v=2', data)
    return response.data
}

async function verifyMobile(mobile, langCode) {
    const response = await http.post('users/register/sendMobileVerificationCode', { mobile, langCode })
    return response.data
}

async function getTransaction(id) {
    const response = await http.get(`transactions/${id}`)
    return response.data
}

async function verifyVat(vat) {
    const response = await http.post(`acceptors/legalEntity/verifyVAT`, { vat })
    return response.data.companyInfo
}

async function changeLanguage(language) {
    const response = await http.post('/users/changeLanguage', { language })
    return response.data.accessToken
}

function updateAccount(legalEntityId, brandId, iban) {
    return http.put(`acceptors/${legalEntityId}/${brandId}/account`, { iban })
}

async function addLEComplete(vat, brandName, iban, isVATLiable, location, settlementLevel) {
    const response = await http.post(`acceptors/legalEntityComplete`, { vat, brandName, iban, isVATLiable, location, settlementLevel })
    return response.data
}

function updateBrand(legalEntityId, brandId, updateData) {
    return http.put(`acceptors/${legalEntityId}/${brandId}`, updateData)
}


/**
 * Change brand's location data
 */
function updateLocation(locationId, leId, brandId, cutoffHour) {
    return http.put(`acceptors/${leId}/${brandId}/locations/${locationId}`, { cutoffHour })
}

async function getMyCompaniesInLightspeed(lightspeedToken) {
    const response = await http.post("lightspeed/companies", { token: lightspeedToken })
    return response.data.companies
}

async function fetchLightspeedToken(code) {
    const response = await http.post("lightspeed/token", { code })
    return response.data.access_token
}

function registerBonsaiInLightspeed(lightspeedToken, brandId, lightspeedCompanyId, paymentUrl) {

    const data = {
        token: lightspeedToken,
        brandId,
        companyId: lightspeedCompanyId,
        url: paymentUrl
    }

    return http.post("lightspeed/registerbonsai", data)
}

export default {
    getUserProfile,
    getLegalEntities,
    getBrands,
    getCashiers,
    deleteCashier,
    addCashier,
    getTransactions,
    getInvoices,
    changeAddress,
    changeEmail,
    createTrx,
    sendSupportquestion,
    register,
    verifyVat,
    getUserLocation,
    addLEComplete,
    changeLanguage,
    getAccounts,
    updateAccount,
    updateBrand,
    updateLocation,
    loadTrxFilter,
    getTransaction,
    downloadCsv,
    getMyCompaniesInLightspeed,
    registerBonsaiInLightspeed,
    fetchLightspeedToken,
    generateStaticQR,
    verifyMobile
}