import api from '@/services/apiService'

export default {
  state: {
    brands: null
  },
  getters: {
    brands: (state) => {
      return state.brands
    },
    brandsOfLE: (state) => (leId) => {
      if (!state.brands) return null
      return state.brands.filter(p => p.legalEntityId == leId)
    }
  },
  mutations: {
    setBrands: (state, payload) => {
      state.brands = payload
    }
  },
  actions: {
    loadBrands: ({ commit }) => {
      api.getBrands().then((brands) => {
        commit('setBrands', brands)
      })
    }
  }
}
