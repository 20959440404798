<template>
  <div class="mymodal" @mousedown.self='clickedOnBackdrop'>

    <div class="mymodal-content" :style="computedStyle">
      <v-card tile class="ma-0">
	  
        <v-toolbar dark v-if="!headless">
          <v-toolbar-title>{{computedTitle}}</v-toolbar-title>
          <v-spacer></v-spacer>
           <v-toolbar-items>
              <v-btn icon dark @click.native='clickedClose'><v-icon>close</v-icon></v-btn>
            </v-toolbar-items>
        </v-toolbar>
		
        <div class="pa-3">
          <div style="text-align: right">
          <v-icon @click="clickedClose">mdi-close</v-icon>
          </div>
          <div :id="container + '_content'">
            HERE SHOULD RENDER A COMPONENT. IF YOU SEE THIS MESSAGE, SOMETHING IS WRONG
          </div>
        </div>
      </v-card>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    title: {
        type: String,
        default: ''
    },
	headless: {
    type: Boolean,
    default: true
  },
  container: {
      type: String,
      default: ''
  },
	allowBackdropClose: {
        type: Boolean,
        default: true
    },
    styling: {
      type: Object
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
	clickedOnBackdrop() {
		if (this.allowBackdropClose)
			this.close()
	},
    clickedClose () {
      this.$emit('close')
    }
  },
  computed: {
    computedTitle () { return this.title || '' },
    computedStyle () { return this.styling || {} }
  }
}
</script>
