import Vue from 'vue'
import Vuex from 'vuex'

import userProfile from './modules/userProfile'
import legalEntities from './modules/legalEntities'
import ui from './modules/ui'
import brands from './modules/brands'

Vue.use(Vuex)

export const store = new Vuex.Store({
  strict: true,
  modules: {
    userProfile,
    legalEntities,
    ui,
    brands
  }
})
